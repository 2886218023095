import { useAccount, useMsal } from "@azure/msal-react";

import { Navigation } from "@anwb/poncho/components/navigation";

function Profile() {
  const { accounts, instance } = useMsal();
  const account = useAccount(accounts[0] || {});

  return (
    <Navigation.Action reference="login">
      <Navigation.ActionToggleLogin
        reference="login"
        icon="profile"
        iconStatusIndicator="logged-in"
        data-hj-suppress
        data-testid="name"
      >
        {account?.name}
      </Navigation.ActionToggleLogin>
      <Navigation.ActionSheet reference="login">
        <Navigation.ActionSheetTitle>{account?.name}</Navigation.ActionSheetTitle>

        <Navigation.Divider />

        <Navigation.ActionSheetLink
          onClick={() => {
            instance.logout().then(
              () => {},
              () => {},
            );
          }}
        >
          Uitloggen
        </Navigation.ActionSheetLink>
      </Navigation.ActionSheet>
    </Navigation.Action>
  );
}

export default Profile;

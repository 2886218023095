import { useEffect, useState } from "react";

import { format } from "date-fns";

import Grid from "@anwb/poncho/components/grid";

import { ProductCode } from "@licentiekantoor/shared/src/types/salesforce";
import enumToOptions from "@licentiekantoor/shared/src/util/enumToOptions";

import FilterQueryBuilder from "../../helpers/FilterQueryBuilder";
import { useAuthorizedRequest } from "../../helpers/api";
import { useStore } from "../../store/useStore";
import { FilterConfiguration, FilterKeyword, FilterType } from "../../types/filters";
import { Order, Status } from "../../types/order";
import Filters from "../Filters/Filters";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

import OrderTable from "./OrderTable/OrderTable";

const getStartDateOptions = () => {
  const options: { [key: string]: string } = {};
  const startSchoolyear = new Date(2022, 7, 1);

  // starting in 2022 when Streetwise was launched,
  // till current year and the next 3 years
  while (startSchoolyear.getFullYear() < new Date().getFullYear() + 3) {
    options[format(startSchoolyear, "y-MM-dd")] = `${startSchoolyear.getFullYear()}-${
      startSchoolyear.getFullYear() + 1
    }`;

    startSchoolyear.setFullYear(startSchoolyear.getFullYear() + 1);
  }
  return options;
};

const filtersForCurrentView: FilterConfiguration[] = [
  {
    type: FilterType.OptionalBoolean,
    keyword: FilterKeyword.IsOpenOnBasispoort,
    label: "Open on Basispoort",
  },
  {
    type: FilterType.Select,
    keyword: FilterKeyword.Status,
    label: "Status",
    options: enumToOptions(Status),
  },
  {
    type: FilterType.Select,
    keyword: FilterKeyword.StartDate,
    label: "School year",
    options: getStartDateOptions(),
  },
  {
    type: FilterType.Select,
    keyword: FilterKeyword.ProductCode,
    label: "Product",
    options: enumToOptions(ProductCode),
  },
];

function Orders() {
  const [orders, setOrders] = useState<Order[]>([]);
  const { isLoading, setRequest } = useAuthorizedRequest<Order[]>();
  const { storeFilters } = useStore((state) => ({
    storeFilters: state.filters,
  }));

  useEffect(() => {
    setRequest({
      path: `/orders${new FilterQueryBuilder(
        storeFilters,
        filtersForCurrentView,
      ).getUrlSearchParamString()}`,
      onSuccess: (data) => {
        setOrders(data);
      },
    });
  }, [setRequest, storeFilters]);

  return (
    <Grid columns={1}>
      <Grid.Item>
        <Filters filters={filtersForCurrentView} />
      </Grid.Item>
      <Grid.Item>{isLoading ? <LoadingSpinner /> : <OrderTable orders={orders} />}</Grid.Item>
    </Grid>
  );
}

export default Orders;

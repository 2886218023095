import { useEffect, useState } from "react";

import { ButtonPrimary, ButtonTertiary } from "@anwb/poncho/components/button";
import Image from "@anwb/poncho/components/image";
import Link from "@anwb/poncho/components/link";
import Table from "@anwb/poncho/components/table";

import { useAuthorizedRequest } from "../../helpers/api";
import { useStore } from "../../store/useStore";
import { Course } from "../../types/course";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

function Courses() {
  const [courses, setCourses] = useState<Course[] | undefined>(undefined);

  const { setNotification } = useStore((state) => ({
    setNotification: state.setNotification,
  }));

  const putCourseRequest = useAuthorizedRequest();
  const putCourse = () => {
    putCourseRequest.setRequest({
      method: "put",
      path: "/courses",
      data: {
        // This page is hidden for Functioneel beheerders, and only used by our own team.
        // Therefore, prompts are fine for now.
        // eslint-disable-next-line no-alert
        id: prompt("Course ID") ?? "",
        // eslint-disable-next-line no-alert
        name: prompt("Course Name") ?? "",
        // eslint-disable-next-line no-alert
        url: prompt("Course URL") ?? "",
      },
      onSuccess: () => {
        setNotification({
          title: "Course successfully added/updated",
          variant: "success",
        });
      },
    });
  };

  const deleteCourseRequest = useAuthorizedRequest();
  const deleteCourse = (courseId: string) => {
    // eslint-disable-next-line no-alert
    if (window.confirm(`Are you sure you want to delete course #${courseId}?`)) {
      deleteCourseRequest.setRequest({
        method: "delete",
        path: `/courses/${courseId}`,
        onSuccess: () => {
          setNotification({
            title: "Course successfully deleted",
            variant: "success",
          });
        },
      });
    }
  };

  const { setRequest, isLoading } = useAuthorizedRequest<Course[]>();
  useEffect(() => {
    setRequest({
      path: "/courses",
      onSuccess: (data) => {
        setCourses(data);
      },
    });
  }, [setRequest]);

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <>
      <Table textAlign="center">
        <Table.Header>
          <Table.Row>
            <Table.HeaderColumn>Course ID</Table.HeaderColumn>
            <Table.HeaderColumn>Name</Table.HeaderColumn>
            <Table.HeaderColumn>Icon</Table.HeaderColumn>
            <Table.HeaderColumn>URL</Table.HeaderColumn>
            <Table.HeaderColumn>Tags</Table.HeaderColumn>
            <Table.HeaderColumn>Actions</Table.HeaderColumn>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {courses?.map((course) => {
            return (
              <Table.Row key={course.id}>
                <Table.Column>{course.id}</Table.Column>
                <Table.Column>{course.name}</Table.Column>
                <Table.Column>
                  <Image alt="Course Logo" src={`data:image/png;base64, ${course.icon}`} />
                </Table.Column>
                <Table.Column>
                  <Link target="_blank" href={course.url}>
                    SAML link
                  </Link>
                </Table.Column>
                <Table.Column>{course.tags.join(", ")}</Table.Column>
                <Table.Column>
                  <ButtonPrimary icon="warning" onClick={() => deleteCourse(course.id)}>
                    Delete
                  </ButtonPrimary>
                </Table.Column>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>

      <ButtonTertiary onClick={() => putCourse()}>Put course on Basispoort</ButtonTertiary>
    </>
  );
}

export default Courses;

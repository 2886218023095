import { useEffect, useState } from "react";

import Grid from "@anwb/poncho/components/grid";

import { Jaargroep } from "@licentiekantoor/shared/src/types/basispoort/Jaargroep";
import enumToOptions from "@licentiekantoor/shared/src/util/enumToOptions";

import FilterQueryBuilder from "../../helpers/FilterQueryBuilder";
import { useAuthorizedRequest } from "../../helpers/api";
import { useStore } from "../../store/useStore";
import { FilterConfiguration, FilterKeyword, FilterType } from "../../types/filters";
import { User } from "../../types/user";
import Filters from "../Filters/Filters";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

import UserTable from "./UserTable/UserTable";

const filtersForCurrentView: FilterConfiguration[] = [
  {
    type: FilterType.OptionalBoolean,
    keyword: FilterKeyword.HasBasispoortLicenseForLicenseManagement,
    label: "Has Basispoort license for License Management",
  },
  {
    type: FilterType.OptionalBoolean,
    keyword: FilterKeyword.HasBasispoortLicenseForStreetwise,
    label: "Has Basispoort license for Streetwise",
  },
  {
    type: FilterType.OptionalBoolean,
    keyword: FilterKeyword.ExistsInMoodle,
    label: "Exists in Moodle",
  },
  {
    type: FilterType.OptionalBoolean,
    keyword: FilterKeyword.SuspendedInMoodle,
    label: "Suspended in Moodle",
  },
  {
    type: FilterType.Text,
    keyword: FilterKeyword.Brinvest,
    label: "Brinvest",
  },
  {
    type: FilterType.Select,
    keyword: FilterKeyword.Jaargroep,
    label: "Jaargroep",
    options: enumToOptions(Jaargroep),
  },
];

function Users() {
  const [users, setUsers] = useState<User[]>([]);
  const { setNotification, storeFilters } = useStore((state) => ({
    setNotification: state.setNotification,
    storeFilters: state.filters,
  }));

  const { isLoading, setRequest } = useAuthorizedRequest<User[]>();
  useEffect(() => {
    setRequest({
      path: `/users${new FilterQueryBuilder(
        storeFilters,
        filtersForCurrentView,
      ).getUrlSearchParamString()}`,
      onSuccess: (data) => {
        setUsers(data);
      },
    });
  }, [setRequest, setNotification, storeFilters]);

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <Grid>
      <Grid.Item>
        <Filters filters={filtersForCurrentView} />
      </Grid.Item>
      <Grid.Item>
        <UserTable users={users || []} />
      </Grid.Item>
    </Grid>
  );
}

export default Users;

import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import { PublicClientApplication } from "@azure/msal-browser";
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from "@azure/msal-react";

import { ANWBBrandRefresh, ANWBThemeProvider, Fonts } from "@anwb/poncho/design-tokens/theme";
import ApplicationSizeProvider from "@anwb/poncho/providers/providers-application";

import BasispoortSearch from "../BasispoortSearch/BasispoortSearch";
import Courses from "../Courses/Courses";
import DemoUsers from "../DemoUsers/DemoUsers";
import Home from "../Home/Home";
import Login from "../Login/Login";
import OrderRoutes from "../Orders/OrderRoutes/OrderRoutes";
import PageLayout from "../PageLayout/PageLayout";
import ProductRoutes from "../Products/ProductRoutes";
import ProductsPrograms from "../ProductsPrograms/ProductsPrograms";
import ProgramRoutes from "../Programs/ProgramRoutes";
import ProgramsJaargroepen from "../ProgramsJaargroepen/ProgramsJaargroepen";
import SchoolRoutes from "../Schools/SchoolRoutes/SchoolRoutes";
import Users from "../Users/Users";

import msalConfig from "./authConfiguration/msalConfig";

const msalInstance = new PublicClientApplication(msalConfig);

function Application() {
  return (
    <ANWBThemeProvider theme={ANWBBrandRefresh}>
      <Fonts />
      <ApplicationSizeProvider>
        <MsalProvider instance={msalInstance}>
          <UnauthenticatedTemplate>
            <BrowserRouter>
              <Routes>
                <Route element={<PageLayout />}>
                  <Route path="/" element={<Login />} />
                  <Route path="*" element={<Navigate to="/" replace />} />
                </Route>
              </Routes>
            </BrowserRouter>
          </UnauthenticatedTemplate>
          <AuthenticatedTemplate>
            <BrowserRouter>
              <Routes>
                <Route element={<PageLayout />}>
                  <Route path="/" element={<Home />} />
                  <Route path="/schools/*" element={<SchoolRoutes />} />
                  <Route path="/orders/*" element={<OrderRoutes />} />
                  <Route path="/users" element={<Users />} />
                  <Route path="/courses" element={<Courses />} />
                  <Route path="/basispoort-search" element={<BasispoortSearch />} />
                  <Route path="/demo-users" element={<DemoUsers />} />
                  <Route path="/products/*" element={<ProductRoutes />} />
                  <Route path="/programs/*" element={<ProgramRoutes />} />
                  <Route path="/products_programs/*" element={<ProductsPrograms />} />
                  <Route path="/programs_jaargroepen/*" element={<ProgramsJaargroepen />} />
                </Route>
              </Routes>
            </BrowserRouter>
          </AuthenticatedTemplate>
        </MsalProvider>
      </ApplicationSizeProvider>
    </ANWBThemeProvider>
  );
}

export default Application;

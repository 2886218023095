import { useEffect, useState } from "react";

import Grid from "@anwb/poncho/components/grid";
import Table from "@anwb/poncho/components/table";

import { useAuthorizedRequest } from "../../helpers/api";
import { Product } from "../../types/product";
import { Program } from "../../types/program";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

import ProductProgramIcon from "./ProductProgramIcon";

function ProductsPrograms() {
  const [products, setProducts] = useState<Product[]>([]);
  const [programs, setPrograms] = useState<Program[]>([]);
  const { setRequest: setProductRequest, isLoading: isProductLoading } =
    useAuthorizedRequest<Product[]>();
  const { setRequest: setProgramRequest, isLoading: isProgramLoading } =
    useAuthorizedRequest<Program[]>();

  useEffect(() => {
    setProductRequest({
      path: `/products`,
      onSuccess: (data) => {
        setProducts(data);
      },
    });
  }, [setProductRequest]);

  useEffect(() => {
    setProgramRequest({
      path: `/programs?relations=products`,
      onSuccess: (data) => {
        setPrograms(data);
      },
    });
  }, [setProgramRequest]);

  return (
    <Grid columns={1}>
      <Grid.Item>
        {isProgramLoading || isProductLoading ? (
          <LoadingSpinner />
        ) : (
          <Table textAlign="center">
            <Table.Header>
              <Table.Row>
                <Table.HeaderColumn>Program</Table.HeaderColumn>
                {products.map((product) => (
                  <Table.HeaderColumn key={product.name}>{product.name}</Table.HeaderColumn>
                ))}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {programs.map((program) => (
                <Table.Row key={program.id}>
                  <Table.Column>{program.name}</Table.Column>
                  {products.map((product) => {
                    return (
                      <Table.Column key={product.name}>
                        <ProductProgramIcon product={product} program={program} />
                      </Table.Column>
                    );
                  })}
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}
      </Grid.Item>
    </Grid>
  );
}

export default ProductsPrograms;

import { useState } from "react";

import { ButtonTertiary } from "@anwb/poncho/components/button";
import Grid from "@anwb/poncho/components/grid";
import Pill, { PillIcon } from "@anwb/poncho/components/pill";
import { Popover } from "@anwb/poncho/components/popover";

import FilterQueryBuilder from "../../helpers/FilterQueryBuilder";
import { useStore } from "../../store/useStore";
import { FilterConfiguration } from "../../types/filters";
import Filter from "../Filter/Filter";

function Filters({ filters }: { filters: FilterConfiguration[] }) {
  const [isOpen, setIsOpen] = useState(false);

  const { setFilters, storeFilters } = useStore((state) => ({
    setFilters: state.setFilters,
    storeFilters: state.filters,
  }));

  const clearAllFilters = () => {
    setFilters({
      jaargroep: "none",
      "is-open-on-basispoort": "none",
      "start-date": "none",
      status: "none",
      "suspended-in-moodle": "none",
      "product-code": "none",
      "has-basispoort-license-for-streetwise": "none",
      "has-basispoort-license-for-license-management": "none",
      "exists-in-moodle": "none",
      brinvest: "",
      "has-actief-orders": "none",
    });
  };

  return (
    <Popover open={isOpen} onClose={() => setIsOpen(false)} offset={50}>
      <Popover.Trigger>
        <Pill isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
          <PillIcon type="ui" variant="filters" />
          <Pill.Text>Filters</Pill.Text>
          <Pill.Counter count={new FilterQueryBuilder(storeFilters, filters).getFilterCount()} />
        </Pill>
      </Popover.Trigger>
      <Popover.Header title="Filters">
        <ButtonTertiary onClick={clearAllFilters}>Clear filters</ButtonTertiary>
      </Popover.Header>
      <Popover.Body>
        <Grid columns={Math.min(filters.length, 4)}>
          {filters.map((filter) => {
            return (
              <Grid.Item key={filter.keyword}>
                <Filter filter={filter} />
              </Grid.Item>
            );
          })}
        </Grid>
      </Popover.Body>
      <Popover.Footer>
        <ButtonTertiary onClick={() => setIsOpen(false)}>Close</ButtonTertiary>
      </Popover.Footer>
    </Popover>
  );
}

export default Filters;

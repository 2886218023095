import { useNavigate } from "react-router-dom";

import { ButtonPrimary, ButtonTertiary } from "@anwb/poncho/components/button";
import Link from "@anwb/poncho/components/link";
import Table from "@anwb/poncho/components/table";
import Typography from "@anwb/poncho/components/typography";

import truncateLeft from "@licentiekantoor/shared/src/util/truncateLeft";

import { useAuthorizedRequest } from "../../../helpers/api";
import { useStore } from "../../../store/useStore";
import { User } from "../../../types/user";
import BooleanIcon from "../../BooleanIcon/BooleanIcon";

type UserTableProps = {
  users: User[];
};

const MAX_ECK_ID_LENGTH = 30;

function UserTable({ users }: UserTableProps) {
  const { moodleBaseURL, setNotification } = useStore((state) => ({
    moodleBaseURL: state.moodleBaseURL,
    setNotification: state.setNotification,
  }));
  const navigate = useNavigate();

  const deleteUsersRequest = useAuthorizedRequest();
  const deleteUsers = (basispoortUserIds: number[]) => {
    if (
      // eslint-disable-next-line no-alert
      window.confirm(
        `Are you sure you want to delete ${
          basispoortUserIds.length === 1 ? "this user" : "these users"
        }`,
      )
    ) {
      deleteUsersRequest.setRequest({
        method: "delete",
        path: `/users`,
        data: {
          basispoortUserIds,
        },
        onSuccess: () => {
          setNotification({
            title:
              basispoortUserIds.length === 1
                ? `User has been deleted.`
                : `Users have been deleted.`,
            variant: "success",
          });
        },
      });
    }
  };

  return (
    <>
      <Typography variant="number">
        {users.length} user{users.length !== 1 ? "s" : ""} found
      </Typography>
      <Table textAlign="center">
        <Table.Header>
          <Table.Row>
            <Table.HeaderColumn>School</Table.HeaderColumn>
            <Table.HeaderColumn>Basispoort ID</Table.HeaderColumn>
            <Table.HeaderColumn>Is Teacher</Table.HeaderColumn>
            <Table.HeaderColumn>Jaargroep</Table.HeaderColumn>
            <Table.HeaderColumn>Moodle ID</Table.HeaderColumn>
            <Table.HeaderColumn>ECK ID</Table.HeaderColumn>
            <Table.HeaderColumn>Suspended in Moodle</Table.HeaderColumn>
            <Table.HeaderColumn>Actions</Table.HeaderColumn>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {users?.map((user) => {
            return (
              <Table.Row key={user.basispoortUserId}>
                <Table.Column>
                  <ButtonTertiary onClick={() => navigate(`/schools/${user.school.brinvest}`)}>
                    {user.school.brinvest}
                  </ButtonTertiary>
                </Table.Column>
                <Table.Column>{user.basispoortUserId}</Table.Column>
                <Table.Column>
                  <BooleanIcon isTrue={user.isTeacher} />
                </Table.Column>
                <Table.Column>{user.jaargroep}</Table.Column>
                <Table.Column>
                  {user.moodleUserId &&
                    ((moodleBaseURL !== undefined && (
                      <Link
                        href={`${moodleBaseURL}/user/profile.php?id=${user.moodleUserId}`}
                        iconInverted={false}
                        target="_blank"
                      >
                        {user.moodleUserId}
                      </Link>
                    )) ||
                      user.moodleUserId)}
                </Table.Column>
                <Table.Column title={user.eckId ?? ""}>
                  {truncateLeft(user.eckId ?? "", MAX_ECK_ID_LENGTH)}
                </Table.Column>
                <Table.Column>
                  <BooleanIcon isTrue={user.suspendedInMoodle} />
                </Table.Column>
                <Table.Column>
                  <ButtonPrimary
                    onClick={() => deleteUsers([user.basispoortUserId])}
                    icon="cross"
                    iconPosition="before"
                  >
                    Delete
                  </ButtonPrimary>
                </Table.Column>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </>
  );
}

export default UserTable;

import { Route, Routes } from "react-router-dom";

import Programs from "./Programs";
import ProgramsPerProduct from "./ProgramsPerProduct";
import SaveProgram from "./SaveProgram";

function ProgramRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Programs />} />
      <Route path="/:product" element={<ProgramsPerProduct />} />
      <Route path="/add" element={<SaveProgram />} />
      <Route path="/edit/:programId" element={<SaveProgram />} />
    </Routes>
  );
}

export default ProgramRoutes;

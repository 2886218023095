import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Typography from "@anwb/poncho/components/typography";

import { Instelling } from "@licentiekantoor/shared/src/types/basispoort/Instelling";
import { assertIsDefined } from "@licentiekantoor/shared/src/util/assertIsDefined";

import { useAuthorizedRequest } from "../../../helpers/api";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import GroepTable from "../Tables/GroepTable/GroepTable";
import ICTCoordinatorTable from "../Tables/ICTCoordinatorTable/ICTCoordinatorTable";
import LeerkrachtTable from "../Tables/LeerkrachtTable/LeerkrachtTable";
import LeerlingTable from "../Tables/LeerlingTable/LeerlingTable";

import { StyledTableContainer } from "./styles/basispoortinstelling.styles";

type BasispoortInstellingProps = {
  brinvest: string;
};

function BasispoortInstelling() {
  const { brinvest } = useParams<BasispoortInstellingProps>();
  assertIsDefined(brinvest, "Unknown BRINVEST");

  const [instellingData, setInstellingData] = useState<Instelling | undefined>(undefined);

  const { setRequest, isLoading } = useAuthorizedRequest<Instelling>();

  useEffect(() => {
    setRequest({
      path: `/schools/${brinvest}/get-basispoort-instelling`,
      onSuccess: (data) => {
        setInstellingData(data);
      },
    });
  }, [setRequest, brinvest]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (instellingData === undefined) {
    return null;
  }

  return (
    <>
      <StyledTableContainer>
        <Typography variant="component-title">Leerlingen</Typography>
        {instellingData.leerlingen !== undefined ? (
          <LeerlingTable leerlingen={instellingData.leerlingen} />
        ) : (
          <Typography variant="body-text">There are no &apos;leerlingen&apos; found.</Typography>
        )}
      </StyledTableContainer>

      <StyledTableContainer>
        <Typography variant="component-title">Leerkrachten</Typography>
        {instellingData.leerkrachten !== undefined ? (
          <LeerkrachtTable leerkrachten={instellingData.leerkrachten} />
        ) : (
          <Typography variant="body-text">There are no &apos;leerkrachten&apos; found.</Typography>
        )}
      </StyledTableContainer>

      <StyledTableContainer>
        <Typography variant="component-title">Groepen</Typography>
        {instellingData.groepen !== undefined ? (
          <GroepTable groepen={instellingData.groepen} />
        ) : (
          <Typography variant="body-text">There are no &apos;groepen&apos; found.</Typography>
        )}
      </StyledTableContainer>

      <StyledTableContainer>
        <Typography variant="component-title">ICT-coördinatoren</Typography>
        {instellingData.ictCoordinatoren !== undefined ? (
          <ICTCoordinatorTable ictcoordinatoren={instellingData.ictCoordinatoren} />
        ) : (
          <Typography variant="body-text">
            There are no &apos;ICT-Coördinatoren&apos; found.
          </Typography>
        )}
      </StyledTableContainer>
    </>
  );
}

export default BasispoortInstelling;

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ButtonTertiary } from "@anwb/poncho/components/button";
import Grid from "@anwb/poncho/components/grid";

import { useAuthorizedRequest } from "../../helpers/api";
import { Program } from "../../types/program";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

import ProgramTable from "./ProgramTable";

function Programs() {
  const [programs, setPrograms] = useState<Program[]>([]);
  const { isLoading, setRequest } = useAuthorizedRequest<Program[]>();

  const navigate = useNavigate();

  useEffect(() => {
    setRequest({
      path: `/programs`,
      onSuccess: (data) => {
        setPrograms(data);
      },
    });
  }, [setRequest]);

  return (
    <Grid columns={1}>
      <Grid.Item>
        <ButtonTertiary onClick={() => navigate(`/products_programs`)}>
          Match programs with products
        </ButtonTertiary>

        <ButtonTertiary onClick={() => navigate(`/programs_jaargroepen`)}>
          Match jaargroepen with programs
        </ButtonTertiary>

        <ButtonTertiary onClick={() => navigate(`/programs/add`)}>Add program</ButtonTertiary>
      </Grid.Item>
      <Grid.Item>{isLoading ? <LoadingSpinner /> : <ProgramTable programs={programs} />}</Grid.Item>
    </Grid>
  );
}

export default Programs;

import { FilterConfiguration, FilterType } from "../../types/filters";

import OptionalBooleanFilter from "./OptionalBooleanFilter";
import SelectFilter from "./SelectFilter";
import TextFilter from "./TextFilter";

function Filter({ filter }: { filter: FilterConfiguration }) {
  switch (filter.type) {
    case FilterType.OptionalBoolean:
      return <OptionalBooleanFilter filter={filter} />;
    case FilterType.Select:
      return <SelectFilter filter={filter} />;
    case FilterType.Text:
      return <TextFilter filter={filter} />;
    default:
      return <span>Filter type is missing</span>;
  }
}

export default Filter;

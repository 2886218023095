import { ChangeEvent } from "react";

import FormFieldSelect from "@anwb/poncho/components/form-field-select";

import { useStore } from "../../store/useStore";
import { SelectFilterConfiguration } from "../../types/filters";

const buildOptions = (options: { [key: string]: string }) => {
  const returnOptions = [
    {
      name: "-",
      value: "none",
    },
  ];

  returnOptions.push(
    ...Object.entries(options).map(([value, name]) => {
      return { name, value };
    }),
  );

  return returnOptions;
};

function SelectFilter({ filter }: { filter: SelectFilterConfiguration }) {
  const { filters, setFilters } = useStore((state) => ({
    filters: state.filters,
    setFilters: state.setFilters,
  }));

  const value = filters[filter.keyword] ?? "none";

  const setValue = (newValue: string) => {
    setFilters({ ...filters, ...{ [filter.keyword]: newValue } });
  };

  return (
    <FormFieldSelect
      onChange={(event: ChangeEvent<HTMLSelectElement>) => {
        setValue(event.target.value);
      }}
      label={filter.label}
      required
      options={buildOptions(filter.options)}
      name={filter.keyword}
      reference={filter.keyword}
      value={value}
    />
  );
}

export default SelectFilter;

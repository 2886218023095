import Grid from "@anwb/poncho/components/grid";
import Table from "@anwb/poncho/components/table";
import Typography from "@anwb/poncho/components/typography";

import { Leerkracht } from "@licentiekantoor/shared/src/types/basispoort/Leerkracht";

type LeerkrachtenTableProps = {
  leerkrachten: Leerkracht[];
};

function LeerkrachtTable({ leerkrachten }: LeerkrachtenTableProps) {
  return leerkrachten.length !== 0 ? (
    <Table textAlign="center">
      <Table.Header>
        <Table.Row>
          <Table.HeaderColumn>Id</Table.HeaderColumn>
          <Table.HeaderColumn>Achternaam</Table.HeaderColumn>
          <Table.HeaderColumn>Voornaam</Table.HeaderColumn>
          <Table.HeaderColumn>E-mailadres</Table.HeaderColumn>
          <Table.HeaderColumn>Groepen</Table.HeaderColumn>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {leerkrachten.map((leerkracht) => (
          <Table.Row key={leerkracht.id}>
            <Table.Column>{leerkracht.id}</Table.Column>
            <Table.Column>{leerkracht.achternaam}</Table.Column>
            <Table.Column>{leerkracht.voornaam}</Table.Column>
            <Table.Column>{leerkracht.emailadres}</Table.Column>
            <Table.Column>
              <Grid>
                {leerkracht.groepen?.map((groep) => <Grid.Item key={groep}>{groep}</Grid.Item>)}
              </Grid>
            </Table.Column>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  ) : (
    <Typography variant="body-text">There are no &apos;leerkrachten&apos; found.</Typography>
  );
}

export default LeerkrachtTable;

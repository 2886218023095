import Typography from "@anwb/poncho/components/typography";

function Home() {
  return (
    <div>
      <Typography variant="content-title">Welcome to the License Office.</Typography>
      Contact <a href="mailto:itverkeerseducatie@anwb.nl">itverkeerseducatie@anwb.nl</a> for support
      or go to{" "}
      <a href="https://anwb.atlassian.net/wiki/spaces/LMSA/pages/40503349">the FAQ on Confluence</a>
      .
    </div>
  );
}

export default Home;

import Table from "@anwb/poncho/components/table";
import Typography from "@anwb/poncho/components/typography";

import { ICTCoordinator } from "@licentiekantoor/shared/src/types/basispoort/ICTCoordinator";

type ICTCoordinatorTableProps = {
  ictcoordinatoren: ICTCoordinator[];
};

function ICTCoordinatorTable({ ictcoordinatoren }: ICTCoordinatorTableProps) {
  return ictcoordinatoren.length !== 0 ? (
    <Table textAlign="center">
      <Table.Header>
        <Table.Row>
          <Table.HeaderColumn>Id</Table.HeaderColumn>
          <Table.HeaderColumn>Achternaam</Table.HeaderColumn>
          <Table.HeaderColumn>Voornaam</Table.HeaderColumn>
          <Table.HeaderColumn>Voorvoegsel</Table.HeaderColumn>
          <Table.HeaderColumn>E-mailadres</Table.HeaderColumn>
          <Table.HeaderColumn>Einddatum</Table.HeaderColumn>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {ictcoordinatoren.map((ictcoordinator) => (
          <Table.Row key={ictcoordinator.id}>
            <Table.Column>{ictcoordinator.id}</Table.Column>
            <Table.Column>{ictcoordinator.achternaam}</Table.Column>
            <Table.Column>{ictcoordinator.voornaam}</Table.Column>
            <Table.Column>{ictcoordinator.voorvoegsel}</Table.Column>
            <Table.Column>{ictcoordinator.emailadres}</Table.Column>
            <Table.Column>{ictcoordinator.einddatum}</Table.Column>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  ) : (
    <Typography variant="body-text">There are no &apos;ICT-coördinatoren&apos; found.</Typography>
  );
}

export default ICTCoordinatorTable;

import { LinkContainer } from "@anwb/poncho/components/link";

import { Jaargroep } from "@licentiekantoor/shared/src/types/basispoort/Jaargroep";

import { useAuthorizedRequest } from "../../helpers/api";
import { Program } from "../../types/program";
import BooleanIcon from "../BooleanIcon/BooleanIcon";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

function ProgramJaargroepIcon({ program, jaargroep }: { program: Program; jaargroep: Jaargroep }) {
  const changeRequest = useAuthorizedRequest<{ status: "success" }>();

  const addProgramToJaargroep = (programToBeAddedTo: Program, jaargroepToAdd: Jaargroep) => {
    changeRequest.setRequest({
      method: "post",
      path: `/programs/${programToBeAddedTo.id}/jaargroep/${jaargroepToAdd}`,
      onSuccess: () => {
        programToBeAddedTo.jaargroepen.push(jaargroepToAdd);
      },
    });
  };

  const removeProgramFromJaargroep = (programToBeAddedTo: Program, jaargroepToAdd: Jaargroep) => {
    changeRequest.setRequest({
      method: "delete",
      path: `/programs/${programToBeAddedTo.id}/jaargroep/${jaargroepToAdd}`,
      onSuccess: () => {
        const jaargroepIndex = programToBeAddedTo.jaargroepen.indexOf(jaargroepToAdd);

        if (jaargroepIndex > -1) {
          programToBeAddedTo.jaargroepen.splice(jaargroepIndex, 1);
        }
      },
    });
  };

  if (changeRequest.isLoading) {
    return <LoadingSpinner />;
  }

  if (program.jaargroepen.includes(jaargroep)) {
    return (
      <LinkContainer
        onClick={() => {
          removeProgramFromJaargroep(program, jaargroep);
        }}
      >
        <BooleanIcon isTrue />
      </LinkContainer>
    );
  }
  return (
    <LinkContainer
      onClick={() => {
        addProgramToJaargroep(program, jaargroep);
      }}
    >
      <BooleanIcon />
    </LinkContainer>
  );
}

export default ProgramJaargroepIcon;

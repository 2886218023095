import FormFieldInput from "@anwb/poncho/components/form-field-input";

export default function FormField<
  Values extends Record<ValidProps, string>,
  ValidProps extends string,
>({
  keyword,
  label,
  useState,
  required,
}: {
  keyword: ValidProps;
  label: string;
  useState: [Values, React.Dispatch<React.SetStateAction<Values>>];
  required?: boolean;
}) {
  const onChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const updatedState = { ...useState[0], [keyword]: evt.target.value.trim() };
    useState[1](updatedState);
  };
  return (
    <FormFieldInput
      type={keyword === "email" ? "email" : "text"}
      reference={keyword}
      name={keyword}
      required={required}
      label={label}
      value={useState[0][keyword]}
      onChange={onChange}
    />
  );
}

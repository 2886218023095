import { useLocation, useNavigate } from "react-router-dom";

import { AuthenticatedTemplate } from "@azure/msal-react";

import { Navigation } from "@anwb/poncho/components/navigation";

const menuItems = [
  {
    label: "Schools",
    to: "/schools",
  },
  {
    label: "Orders",
    to: "/orders",
  },
  {
    label: "Search Basispoort",
    to: "/basispoort-search",
  },
  {
    label: "Demo Users",
    to: "/demo-users",
  },
  {
    label: "Products",
    to: "/products",
  },
  {
    label: "Programs",
    to: "/programs",
  },
];

export default function MainNavigation() {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Navigation.Main>
      <Navigation.Items>
        <Navigation.Item key="license-office" reference="license-office">
          <Navigation.SubItemsToggle
            reference="License Office"
            href="#"
            highlighted={location.pathname === "/"}
            label="License Office"
            onClick={() => {
              navigate("/");
            }}
          >
            <Navigation.LinkLabel>License Office</Navigation.LinkLabel>
          </Navigation.SubItemsToggle>
        </Navigation.Item>

        <AuthenticatedTemplate>
          {menuItems.map((menuItem) => (
            <Navigation.Item key={menuItem.label} reference={menuItem.label}>
              <Navigation.SubItemsToggle
                reference={menuItem.label}
                href="#"
                highlighted={location.pathname === menuItem.to}
                label={menuItem.label}
                onClick={() => {
                  navigate(menuItem.to);
                }}
              >
                <Navigation.LinkLabel>{menuItem.label}</Navigation.LinkLabel>
              </Navigation.SubItemsToggle>
            </Navigation.Item>
          ))}
        </AuthenticatedTemplate>
      </Navigation.Items>
    </Navigation.Main>
  );
}

import { useMsal } from "@azure/msal-react";

import { ButtonTertiary } from "@anwb/poncho/components/button";
import Grid from "@anwb/poncho/components/grid";

function Login() {
  const { instance } = useMsal();

  return (
    <Grid>
      <Grid.Item>
        <ButtonTertiary
          onClick={() => {
            instance
              .loginRedirect()
              .then(() => {
                // successfully redirected
              })
              .catch((e) => {
                throw e;
              });
          }}
        >
          Login using Azure AD
        </ButtonTertiary>
      </Grid.Item>
    </Grid>
  );
}

export default Login;

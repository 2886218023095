import { OptionalBooleanFilterConfiguration, SelectFilterConfiguration } from "../../types/filters";

import SelectFilter from "./SelectFilter";

function OptionalBooleanFilter({ filter }: { filter: OptionalBooleanFilterConfiguration }) {
  const selectFilter = {
    ...(filter as Partial<OptionalBooleanFilterConfiguration>),
    type: "Select",
    options: {
      true: "Yes",
      false: "No",
    },
  } as SelectFilterConfiguration;

  return <SelectFilter filter={selectFilter} />;
}

export default OptionalBooleanFilter;

export enum FilterKeyword {
  IsOpenOnBasispoort = "is-open-on-basispoort",
  Status = "status",
  StartDate = "start-date",
  SuspendedInMoodle = "suspended-in-moodle",
  ProductCode = "product-code",
  HasBasispoortLicenseForStreetwise = "has-basispoort-license-for-streetwise",
  HasBasispoortLicenseForLicenseManagement = "has-basispoort-license-for-license-management",
  ExistsInMoodle = "exists-in-moodle",
  Brinvest = "brinvest",
  Jaargroep = "jaargroep",
  HasActiefOrders = "has-actief-orders",
}

export enum OptionalBooleanOption {
  True = "true",
  False = "false",
}

export enum FilterType {
  OptionalBoolean = "OptionalBoolean",
  Select = "Select",
  Text = "Text",
}

export type StoreFilters = { [key in FilterKeyword]: string };

export interface BaseFilterConfiguration {
  type: FilterType;
  label: string;
  keyword: FilterKeyword;
  currentValue?: string;
}

export interface TextFilterConfiguration extends BaseFilterConfiguration {
  type: FilterType.Text;
}

export interface SelectFilterConfiguration extends BaseFilterConfiguration {
  type: FilterType.Select;
  options: { [key: string]: string };
}

export interface OptionalBooleanFilterConfiguration extends BaseFilterConfiguration {
  type: FilterType.OptionalBoolean;
  currentValue?: OptionalBooleanOption;
}

export type FilterConfiguration =
  | SelectFilterConfiguration
  | OptionalBooleanFilterConfiguration
  | TextFilterConfiguration;

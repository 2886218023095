import { LinkContainer } from "@anwb/poncho/components/link";

import { useAuthorizedRequest } from "../../helpers/api";
import { Product } from "../../types/product";
import { Program } from "../../types/program";
import BooleanIcon from "../BooleanIcon/BooleanIcon";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

function ProductProgramIcon({ program, product }: { program: Program; product: Product }) {
  const changeRequest = useAuthorizedRequest<{ status: "success" }>();

  const addProgramToProduct = (programToAdd: Program, productToBeAddedTo: Product) => {
    changeRequest.setRequest({
      method: "post",
      path: `/programs/${programToAdd.id}/product/${productToBeAddedTo.name}`,
      onSuccess: () => {
        programToAdd.products.push(productToBeAddedTo);
      },
    });
  };

  const removeProgramFromProduct = (programToRemove: Program, productToBeRemoveFrom: Product) => {
    changeRequest.setRequest({
      method: "delete",
      path: `/programs/${programToRemove.id}/product/${productToBeRemoveFrom.name}`,
      onSuccess: () => {
        const productIndex = programToRemove.products.findIndex(
          (p) => p.name === productToBeRemoveFrom.name,
        );

        if (productIndex > -1) {
          programToRemove.products.splice(productIndex, 1);
        }
      },
    });
  };

  if (changeRequest.isLoading) {
    return <LoadingSpinner />;
  }

  if (program.products.map((p) => p.name).includes(product.name)) {
    return (
      <LinkContainer
        onClick={() => {
          removeProgramFromProduct(program, product);
        }}
      >
        <BooleanIcon isTrue />
      </LinkContainer>
    );
  }
  return (
    <LinkContainer
      onClick={() => {
        addProgramToProduct(program, product);
      }}
    >
      <BooleanIcon />
    </LinkContainer>
  );
}

export default ProductProgramIcon;

import React from "react";
import { createRoot } from "react-dom/client";

import Application from "./components/Application/Application";

const container = document.getElementById("@licentiekantoor/front-end");
if (container) {
  const root = createRoot(container);
  root.render(<Application />);
}
